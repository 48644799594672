import React, { useEffect, useState } from "react";
import {
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import GetCellContents from "./TableContent";
import { selectProjectAction } from "../../redux/slices/project/selected";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearLoginLoading } from "redux/slices/basic/loginSlice";

const columns = [
  { key: "project_title", label: "PROJECTS", width: "10%" },
  { key: "project_description", label: "DESCRIPTION" },
  { key: "username", label: "Email" },
  { key: "project_date", label: "DATE" },
  { key: "project_action", label: "ACTION" },
];

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "&.MuiTableRow-hover": {
    "&:hover": {
      backgroundColor: "#FFC500",
      cursor: "pointer",
    },
  },
}));

export default function ProjectList({ cellContents }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(clearLoginLoading());
  })
  const handleRowClick = (project) => {
    dispatch(selectProjectAction({ project: project, history: history }));
  };

  const filteredContents = cellContents?.length && cellContents.filter((content) => {
    return content?.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
  }
  );

  return (
    <Box>
      <TextField
        label="Search"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        sx={{ marginBottom: "10px" }}
      />
      <TableContainer
        sx={{
          maxHeight: 370,
          borderRadius: "12px !important",
          marginBottom: "10px",
        }}
        component={Paper}
      >
        <Box>
          <Box
            sx={{ width: "100%", display: "table", tableLayout: "fixed" }}
          >
            <Table
              sx={{ minWidth: '50%' }}
              aria-label="project-list-table"
              style={{ overflowX: "scroll" }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((col, idx) => (
                    <TableCell
                      width={"10%"}
                      style={{
                        fontWeight: "bold",
                      }}
                      key={`project-list-table-head-${idx + 1}`}
                      align={col.alignment}
                    >
                      {col.label || ""}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cellContents?.length && filteredContents?.map((content, idx) => (
                  <TableRowStyled
                    key={`project-list-table-body-${idx}`}
                    onClick={() => handleRowClick(content)}
                    hover
                  >
                    {columns.map((col, index) => (
                      <TableCell
                        align={col.alignment || "left"}
                        key={`project-list-table-body-cell-${idx}-${index}`}
                      >
                        <GetCellContents colKey={col.key} rowData={content} />
                      </TableCell>
                    ))}
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </TableContainer>
    </Box>
  );
}
