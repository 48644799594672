import { call, select, put } from "redux-saga/effects";
import { saveExportRequest, 
  saveMeasureExportRequest,
   saveExportSmartTrackingRequest, 
   saveExportExcelRequest, exportFileDownloadRequest
  } from "../requests/exportRequests";
import { resetDownloadLoading } from "redux/slices/export";

export function* saveOrthoExportHandler() {
  try {
    const flightSelected = yield select(state => state.flightSelected);
    const flight_uuid = flightSelected?.uuid;
    const flightName = flightSelected?.name;
    const projectName = yield select(state => state.selectedProject.name);
    let fileName = `${projectName}-${flightName}-ortho`;
    fileName = fileName.replace(/_+/g, '_');
    const response = yield call(exportFileDownloadRequest, {flight_uuid: flight_uuid, type: 'ortho' } )
    if (response && response.data && response.data.url) {
      fetch(response.data.url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.tif`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
      yield put(resetDownloadLoading());

    };
  } catch (error) {
    console.error(error);
    yield put(resetDownloadLoading());
  }
}

export function* saveDSMExportHandler() {
  try {
    const flightSelected = yield select(state => state.flightSelected);
    const flight_uuid = flightSelected?.uuid;
    const flightName = flightSelected?.name;
    const projectName = yield select(state => state.selectedProject.name);
    let fileName = `${projectName}${flightName}elevation`;
    fileName = fileName.replace(/[\s-]/g, '_');
    const response = yield call(exportFileDownloadRequest, {flight_uuid: flight_uuid, type: 'elevation' } )
    if (response && response.data && response.data.url) {
      fetch(response.data.url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.tif`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        console.error('Error downloading file:', error);
      });
    };
  } catch (error) {
    console.error(error);
  }
}


export function* saveTerrainExportHandler() {
  try {
    const flightSelected = yield select(state => state.flightSelected);
    const flight_uuid = flightSelected?.uuid;
    const flightName = flightSelected?.name;
    const projectName = yield select(state => state.selectedProject.name);
    let fileName = `${projectName}${flightName}hillshade`;
    fileName = fileName.replace(/[\s-]/g, '_');
    const response = yield call(exportFileDownloadRequest, {flight_uuid: flight_uuid, type: 'hillshade' } );
    if (response && response.data && response.data.url) {
      fetch(response.data.url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.tif`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        console.error('Error downloading file:', error);
      });
    };
  } catch (error) {
    console.error(error);
  }
}



export function* saveMeasurementExportHandler() {
  try {
    const flightSelected = yield select(state => state.flightSelected);
    const flightId = flightSelected?.id;
    const flightName = flightSelected?.name;
    const projectName = yield select(state => state.selectedProject.name);
    const fileName = `${projectName}-${flightName}-measurement`;
    const response = yield call(saveMeasureExportRequest, flightId)
    const blob = new Blob([response.data.detail], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    let element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', `${fileName}.kmz`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error)
  }
}


export function* saveExportSmartTrackingHandler() {
  try {
    const flightSelected = yield select(state => state.flightSelected);
    const flightId = flightSelected?.id;
    const flightName = flightSelected?.name;
    const projectName = yield select(state => state.selectedProject.name);
    const fileName = `${projectName}-${flightName}-smart-tracking`;
    const response = yield call(saveExportSmartTrackingRequest, flightId);
    if (response?.data?.detail) {
      let element = document.createElement('a');
      let filename = fileName + '.kml'
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response?.data?.detail, undefined, 2));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    } else {
      console.log("No file")
    }
  } catch (error) {
    console.log(error)
  }
}


export function* saveExportExcelHandler() {
  try {
    const flightSelected = yield select(state => state.flightSelected);
    const flightId = flightSelected?.id;
    const flightName = flightSelected?.name;
    const projectName = yield select(state => state.selectedProject.name);
    const fileName = `${projectName}-${flightName}-excel`;

    const response = yield call(saveExportExcelRequest, flightId);

    // Check if response is successful and contains data
    if (response && response.data) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.xlsx`;
      link.click();
    } else {
      console.error("Invalid or empty response data");
    }
  } catch (error) {
    console.error("Error in saveExportExcelHandler:", error);
  }
}






