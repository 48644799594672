import React, { useEffect, useState } from "react";
import Export from "../../components/Export";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  saveDSMExportAction,
  saveMeasurementExportAction,
  saveOrthoExportAction,
  saveTerrainExportAction,
  saveExportExcelAction,
  saveExportSmartTrackingAction
} from "../../redux/slices/export";
import PrivateRequestBase from "redux/sagas/requests/rootRequest/PrivateRequestBase";
import { setDownloadLoading } from "redux/slices/export";
import { CircularProgress } from "@material-ui/core";

const ExportPage = () => {
  const dispatch = useDispatch();
  const selectedFlightId = useSelector((state) => state.flightSelected.uuid);
  const [exportData, setExportData] = useState(null); 
  const [loading, setLoading] = useState(true);
  const expLoading = useSelector((state) => state?.export?.loading);


  useEffect(() => {
    setLoading(true);

    PrivateRequestBase("get", `api/check_exports/${selectedFlightId}`)
      .then((response) => {
        if (response.data?.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response;
      })
      .then((result) => {
        setExportData(result?.data);
        setLoading(false); 
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); 
      });
  }, [selectedFlightId]);

  const isMeasureDisabled = loading || !(exportData?.measurements > 0);
  const isSmartDisabled = loading || !(exportData?.smart_tracking > 0);

  const onOrthoSave = () => {
    dispatch(setDownloadLoading());
    dispatch(saveOrthoExportAction());
  };

  const onTerrainSave = () => {
    dispatch(saveTerrainExportAction());
  };

  const onDsmSave = () => {
    dispatch(saveDSMExportAction());
  };

  const onMeasureSave = () => {
    dispatch(saveMeasurementExportAction());
  };

  const onSmartTrackingSave = () => {
    dispatch(saveExportSmartTrackingAction());

  }

  const onMeasureExcelSheet = () => {
    dispatch(saveExportExcelAction());
  }

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={2}
        pb={2}
      >
        <Typography variant="h4">Export</Typography>
      </Box>
      <Divider />
      <Grid
        container
        direction="column"
        spacing={1}
        sx={{
          padding: "8px 8px 8px",
        }}
      >
        <Grid item>
          <Export
            tittle="Ortho Photo (tif)"
            avatarIcon="imageIcon"
            onSave={onOrthoSave}
            isDisabled={expLoading}
          />
        </Grid>
        <Grid item>
          <Export
            tittle="Hillshade (tif)"
            avatarIcon="terrain"
            onSave={onTerrainSave}
            isDisabled={false}
          />
        </Grid>
        <Grid item>
          <Export
            tittle="Surface Model (tif)"
            avatarIcon="straightenIcon"
            onSave={onDsmSave}
            isDisabled={false}
          />
        </Grid>
        <Grid item>
          <Export
            tittle="Measurement (kmz)"
            avatarIcon="straightenIcon"
            onSave={onMeasureSave}
            isDisabled={loading || isMeasureDisabled }
          />
        </Grid>
        <Grid item>
          <Export
            tittle="Smart Tracking (kml)"
            avatarIcon="straightenIcon"
            onSave={onSmartTrackingSave}
            isDisabled={loading || isSmartDisabled}
          />
        </Grid>
        <Grid item>
          <Export
            tittle="Measurement (xlsx)"
            avatarIcon="straightenIcon"
            onSave={onMeasureExcelSheet}
            isDisabled={loading || isMeasureDisabled}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ExportPage;
