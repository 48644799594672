import {createSlice} from "@reduxjs/toolkit";

const exportSlice = createSlice({
  name: "exportSlice",
  initialState: {
    loading: false
  },
  reducers: {
    setDownloadLoading(state, action) {
      state.loading = true;
    },
    resetDownloadLoading(state, action) {
      state.loading = false;
    },
    saveOrthoExportAction(){},
    saveTerrainExportAction(){},
    saveMeasurementExportAction(){},
    saveDSMExportAction(){},
    saveExportSmartTrackingAction(){},
    saveExportExcelAction(){},

  }
})

export const { 
  saveTerrainExportAction,
   saveMeasurementExportAction, 
   saveOrthoExportAction, 
   saveDSMExportAction,
  saveExportExcelAction, 
  saveExportSmartTrackingAction,
  setDownloadLoading,
  resetDownloadLoading
} = exportSlice.actions

export default exportSlice.reducer